import { clsx } from 'clsx';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

import { getLastDashboardRoute } from 'util/routes';
import { useAuth } from 'contexts/auth';
import { useChatSupport } from 'contexts/chatSupport';

import Icon from '../common/Icon';
import Tooltip from 'components/common/Tooltip';
import UserBubble from 'components/common/UserBubble';

const SurveyWithSidebar = ({
  children,
  sidebarNav,
  sidebarRight,
  sidebarWorkspace,
}: {
  children: ReactNode;
  sidebarNav: ReactNode;
  sidebarRight?: ReactNode;
  sidebarWorkspace?: ReactNode;
}): JSX.Element => {
  return (
    <div className="w-full bg-white min-h-full h-survey-sidebar flex">
      <div className="fixed top-0 left-0 bg-gray-d-50 w-[82px] h-full pt-[63px]">
        {sidebarNav}
      </div>

      <div className="ml-[82px] grow flex items-end">
        {sidebarWorkspace && (
          <>
            <div className="px-6 pb-32 h-full w-[300px] overflow-auto shrink-0">
              {sidebarWorkspace}
            </div>
            <div className="w-px bg-gray-d-200 h-[calc(100%_-_1.5rem)]" />
          </>
        )}

        <div className="p-6 pt-0 pb-0 grow overflow-auto h-full">
          <div className="pb-24">{children}</div>
        </div>
      </div>

      {sidebarRight}
    </div>
  );
};

export default SurveyWithSidebar;

export const Sidebar = ({ links }: { links: ReactNode }) => {
  const navigate = useNavigate();
  const { onAuthChanged, user } = useAuth();
  const { showChat } = useChatSupport();

  return (
    <div className="flex flex-col w-full h-full pb-6 border-r border-light-grey">
      <div className="mt-4 flex flex-1 flex-col justify-between divide-light-grey">
        <ul className="cursor-pointer px-2">{links}</ul>
        <ul className="mt-4 pt-4 cursor-pointer">
          <SidebarLink
            icon={<Icon id="copy-06" />}
            isCollapsed
            label="Your Surveys"
            onClick={() => {
              navigate(getLastDashboardRoute());
            }}
          />
          <SidebarLink
            icon={<Icon id="help-circle" />}
            isCollapsed
            label="Help"
            onClick={() => {
              showChat();
            }}
          />
          <SidebarLink
            icon={<Icon id="log-out-01" />}
            isCollapsed
            label="Log Out"
            onClick={() => {
              onAuthChanged({ jwt: null });
            }}
          />
        </ul>
      </div>
      {user && (
        <div
          className="pt-3.5 px-4 flex items-center justify-center text-sm cursor-pointer space-x-4"
          onClick={() => {
            navigate('/account');
          }}
        >
          <div className="shrink-0">
            <UserBubble user={user} withTooltip={false} />
          </div>
        </div>
      )}
    </div>
  );
};

export const SidebarPageLink = ({
  children,
  end = true,
  icon,
  to,
}: {
  children: string;
  end?: boolean;
  icon: ReactNode;
  to: string;
}) => {
  return (
    <NavLink
      className={({ isActive }) =>
        clsx(
          'min-h-16 flex flex-col items-center justify-center py-3 p-2 font-semibold space-y-1 rounded text-center',
          {
            'bg-primary-d-700 text-white': isActive,
          },
        )
      }
      end={end}
      to={to}
    >
      <div className="w-4 h-4 flex items-center justify-center">{icon}</div>
      <span className="text-2xs">{children}</span>
    </NavLink>
  );
};

const SidebarLink = ({
  icon,
  isActive = false,
  isCollapsed,
  label,
  onClick,
  thirdPartyClassName = '',
  to,
}: {
  icon: ReactNode;
  isActive?: boolean;
  isCollapsed: boolean;
  label: string;
  onClick?: () => void;
  thirdPartyClassName?: string;
  to?: string;
}): JSX.Element => {
  const className = clsx(
    'flex items-center px-4 py-3.5 cursor-pointer',
    thirdPartyClassName,
    {
      'text-forest font-semibold': isActive,
      'font-medium': !isActive,
      'justify-center': isCollapsed,
    },
  );
  const linkContent = (
    <>
      <div className="w-4 h-4 flex items-center justify-center">{icon}</div>
      <span
        className={clsx('ml-4', {
          hidden: isCollapsed,
          'inline xl:hidden': !isCollapsed,
        })}
      >
        {label}
      </span>
    </>
  );

  const sidebarLink = to ? (
    <Link className={className} to={to}>
      {linkContent}
    </Link>
  ) : (
    <a className={className} onClick={onClick}>
      {linkContent}
    </a>
  );

  return isCollapsed ? (
    <Tooltip placement="right" trigger={sidebarLink}>
      {label}
    </Tooltip>
  ) : (
    sidebarLink
  );
};
